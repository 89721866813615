import React, { useContext, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  LOGIN,
  REGISTER,
  ORDERS,
  CUSTOMERS,
  STALL,
  STALL_SETTING,
  PAYMENT_SETTING,
  DASHBOARD,
  PRODUCTS,
  WALLET,
} from './settings/constants';
import AuthProvider, { AuthContext } from './context/auth';
import { InLineLoader } from './components/InlineLoader/InlineLoader';

import { UserType } from './settings/types';
import { useAppSelector } from './app/hooks';
const AdminLayout = lazy(() => import('./containers/Layout/Layout'));
const Products = lazy(() => import('./containers/Products/Products'));
const Customers = lazy(() => import('./containers/Customers/Customers'));
const Orders = lazy(() => import('./containers/Orders/Orders'));
const Wallet = lazy(() => import('./containers/Wallet/Wallet'));
const StallSetting = lazy(
  () => import('./containers/StallSetting/StallSetting')
);
const PaymentSetting = lazy(
  () => import('./containers/PaymentSetting/PaymentSetting')
);
const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const Stall = lazy(() => import('./containers/Stall/Stall'));
const Login = lazy(() => import('./containers/Login/LoginV2'));
const Registration = lazy(
  () => import('./containers/Registration/Registration')
);
const NotFound = lazy(() => import('./containers/NotFound/NotFound'));
/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes = () => {
  const { type } = useAppSelector((state) => state.user);

  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        {type === UserType.ADMIN ? (
          <Switch>
            <PrivateRoute exact={true} path={DASHBOARD}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <Dashboard />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={STALL}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <Stall />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <Route path={LOGIN}>
              <Login />
            </Route>
            <Route path={REGISTER}>
              <Registration />
            </Route>
            <Route component={NotFound} />
          </Switch>
        ) : (
          <Switch>
            <PrivateRoute exact={true} path={DASHBOARD}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <Dashboard />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={PRODUCTS}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <Products />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={ORDERS}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <Orders />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={CUSTOMERS}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <Customers />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={WALLET}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <Wallet />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={STALL_SETTING}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <StallSetting />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={PAYMENT_SETTING}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <PaymentSetting />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <Route path={LOGIN}>
              <Login />
            </Route>
            <Route path={REGISTER}>
              <Registration />
            </Route>
            <Route component={NotFound} />
          </Switch>
        )}
      </Suspense>
    </AuthProvider>
  );
};

export default Routes;
