import { ApolloError } from 'apollo-client';
import { listOTNByStallID_listOTNByStallID_items as OTN_ITEMST } from '../graphql/types/listOTNByStallID';

export enum UserType {
  ADMIN = 'admin',
  STALL = 'stall',
}

export interface User {
  email: string;
  id: string;
  type: UserType;
}

export interface Store {
  id: string;
  storeName: string;
  storeOwner: string;
  email: string;
  category: string[];
  dateRegistered: Date;
  dateSubscribed: Date;
  status: number;
}

export interface OTN_T {
  stallOTN: OTN_ITEMST[];
  productOTN: OTN_ITEMST[];
  deleteOtn: (options?: any) => Promise<any>;
  error: ApolloError;
  loading: boolean;
}

export interface Login {
  mobileNumber: string;
  password: string;
}

export interface UserT {
  _id: string;
  email: string;
  username: string;
  permissions: string;
  app: string;
  timestamp: string;
  type: string;
}

export interface LoginResponse {
  token: string;
  user: UserT;
}
