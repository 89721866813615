/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-southeast-1",
  // aws_cognito_identity_pool_id: "ap-southeast-1:202128604126",
  aws_cognito_region: "ap-southeast-1",
  aws_user_pools_id: "ap-southeast-1_SPJG2vzOr",
  aws_user_pools_web_client_id: "6r0rjpet4lgbrgfktrc7uuobgt",
  oauth: {},
  aws_appsync_graphqlEndpoint:
    "https://7lxily73mndbhjwyz32pj4nlda.appsync-api.ap-southeast-1.amazonaws.com/graphql",
  aws_appsync_region: "ap-southeast-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "	da2-c4ymvnd77jdrrhwa3d4ouhm5ey",
};

export default awsmobile;
