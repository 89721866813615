import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../app/store';
import { UserT } from '../settings/types';

interface InitialState extends Omit<UserT, 'permissions' | 'app'> {
  token: string;
}

const initialState: InitialState = {
  _id: '',
  email: '',
  username: '',
  type: '',
  timestamp: '',
  token: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser: (state, action: PayloadAction<InitialState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    removeUser: (state) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { saveUser, removeUser } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const user = (state: RootState) => state.user;

export default userSlice.reducer;
