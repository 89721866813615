import axios from 'axios';
import { Login } from '../settings/types';

const { REACT_APP_CLIENT_NAME, REACT_APP_AUTH_SERVICE_URL } = process.env;

export const login = async ({ mobileNumber, password }: Login) => {
  const payload = { app: REACT_APP_CLIENT_NAME, email: mobileNumber, password };
  const { data } = await axios.post(
    `${REACT_APP_AUTH_SERVICE_URL}/api/v1/user/signin`,
    payload
  );

  return data;
};

export const logout = async (id: string, token: string) => {
  const url = `${REACT_APP_AUTH_SERVICE_URL}/api/v1/user/signout/${id}`;

  const options = {
    headers: {
      'authorization-token': token,
    },
  };

  const { data } = await axios.get(url, options);

  return data;
};
