import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { ApolloProvider } from '@apollo/react-hooks';
import Amplify from 'aws-amplify';
import AWSAppSyncClient from 'aws-appsync';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from '../src/app/store';
import { theme } from './theme';
import Routes from './routes';
// import ApolloClient from "apollo-boost";
import * as serviceWorker from './serviceWorker';
import './theme/global.css';
import AuthProvider from './context/auth';
import { createApolloClient } from './helper/createApolloClient';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

type ClientT = AWSAppSyncClient<NormalizedCacheObject>;

// ApolloClient
// @ts-ignore
const client = createApolloClient(awsconfig);

const persistor = persistStore(store);

function App() {
  const engine = new Styletron();

  return (
    <ApolloProvider client={client as ClientT}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
          <BrowserRouter>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <AuthProvider>
                  <Routes />
                </AuthProvider>
              </PersistGate>
            </Provider>
          </BrowserRouter>
        </BaseProvider>
      </StyletronProvider>
    </ApolloProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
