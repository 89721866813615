// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/';
export const STALL = '/stall';
export const PRODUCTS = '/products';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const LOGOUT = '/logout';
export const ORDERS = '/orders';
export const CUSTOMERS = '/customers';
export const WALLET = '/wallet';
export const STALL_SETTING = '/stall-settings';
export const PAYMENT_SETTING = '/payment-setting';
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '₱';
export const REQUIRED_MSG = 'Please fill out this field';

export const AVAILABILITY_OPTIONS = [
  { value: 'OPEN', label: 'Open' },
  { value: 'CLOSE', label: 'Closed' },
];

export const PAYMENT_OPTIONS = [
  { label: 'COD' },
  {
    label: 'Online Payment',
  },
];

export const DATE_OPTIONS = [
  { value: 'Sunday', label: 'Sunday' },
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
];

export enum ENTITY {
  PRODUCT = 'PRODUCT',
  STALL = 'STALL',
}

export const GMAP_KEY = 'AIzaSyDRI_lClvcmBkMGiL704-GdmB8IGflkPAE';

export const PAGE_ACCESS_TOKEN =
  'EAAEWcfmKVZBEBAFTZBvQZAFWbhHiVWAiqpgpxCPHC6RDjICsQlZBzkTlM1sts04nBAbbH1unEdrGswQlZBXehC99J5umyuQPhINy1gzEl9uODZAIKs5r0BKypkk05K9AF3F8B8GvZAKzHKNwXNYusVBH80hIgAJmqutMnXjlqAWDZAtfduTcWfWa';

export const GRAPH_API = `https://graph.facebook.com/v7.0/me/messages?access_token=${PAGE_ACCESS_TOKEN}`;

// OTP API
export const OTP_ENDPOINT = (number: number, fullName: string) =>
  `https://wpkm0mx01j.execute-api.ap-southeast-1.amazonaws.com/dev/api/v1/otp?number=${number}&name=${fullName}`;
