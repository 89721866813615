import React, { useState, useEffect, useContext, createContext } from 'react';
import { Auth } from 'aws-amplify';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { logout } from '../api/auth';
import { removeUser } from '../slices/userSlice';

type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  makeAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  isModalLoginOpen: boolean;
  setIsModalLoginOpen: React.Dispatch<React.SetStateAction<boolean>>;
  signOut: Function;
  user: any;
  errMsg: string;
};

export const AuthContext = createContext({} as AuthProps);

const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = useState(false);
  const [isModalLoginOpen, setIsModalLoginOpen] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [errMsg, setErrMsg] = useState('');

  const authenticatedUser = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!authenticatedUser._id) return;

    const { _id, username, email, type } = authenticatedUser;
    setUser({ id: _id, username, email, type });
    makeAuthenticated(true);
  }, [authenticatedUser]);

  // useEffect(() => {
  //   if (!user) return;

  //   const { attributes } = user;
  //   const status = attributes && attributes['custom:status'];
  //   const type = attributes && attributes['custom:type'];
  //   const deactStatus = ['DBU', 'DBA'];

  //   if (deactStatus.includes(status)) {
  //     setIsModalLoginOpen(true);
  //     console.log('here');
  //   }

  //   if (status === StallStatus['ACTIVE'] || type === UserType['ADMIN'])
  //     makeAuthenticated(true);
  // }, [user]);

  async function authenticate(
    { mobileNumber, password }: { mobileNumber: string; password: string },
    setSubmitting: any,
    cb: () => void
  ) {
    try {
      const res = await Auth.signIn({
        username: mobileNumber,
        password,
      });
      setUser(res);
    } catch (error) {
      setErrMsg(error.message);
      makeAuthenticated(false);
    }
    setSubmitting(false);
  }

  async function signOut() {
    const { _id, token } = authenticatedUser;
    try {
      await logout(_id, token);

      setUser(null);
      dispatch(removeUser());
      makeAuthenticated(false);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        makeAuthenticated,
        isModalLoginOpen,
        setIsModalLoginOpen,
        authenticate,
        signOut,
        errMsg,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    console.error('Cannot find AuthProvider');
    return;
  }

  return { ...context.user };
};

export default AuthProvider;
