import { InMemoryCache } from 'apollo-cache-inmemory';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';

interface AppSyncConfig {
  aws_project_region: string;
  aws_cognito_identity_pool_id: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  oauth: any;
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_region: string;
  aws_appsync_authenticationType: any;
  aws_appsync_apiKey: string;
}

export const createApolloClient = (awsExports: AppSyncConfig) => {
  let initialState = {};

  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  const client = new AWSAppSyncClient(
    {
      disableOffline: true,
      url: awsExports.aws_appsync_graphqlEndpoint,
      region: awsExports.aws_appsync_region,
      auth: {
        type: AUTH_TYPE.API_KEY,
        apiKey: awsExports.aws_appsync_apiKey,
      },
    },
    {
      cache: new InMemoryCache().restore(initialState),
      ssrMode: false,
    }
  );

  return client;
};
